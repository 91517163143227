import React from "react"
import { LayoutWhite } from "@layout"
import { HeroFeature2022Q3 } from "@components/hero"
import SEO from "@components/seo"
import {
  MainBlockPressPublications,
  MainBlockPressMediaCoverage,
  MainBlockPressCompanyNews,
  MainBlockPressMediaAssets,
} from "@components/main-blocks"

const Press = () => (
  <LayoutWhite
    backgroundImage="press"
    hero={
      <HeroFeature2022Q3
        title="Press &amp; Media"
        // subtitle="See who is talking about ATM and explore our media kit."
        subtitle="Media Contact: press@atm.com"
      />
    }
  >
    <SEO title="Press &amp; Media" />
    <MainBlockPressPublications />
    <MainBlockPressMediaCoverage />
    <MainBlockPressCompanyNews />
    <MainBlockPressMediaAssets />
  </LayoutWhite>
)

export default Press
